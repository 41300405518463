<template>
  <div
    class="modal fade"
    id="paymentModal"
    tabindex="-1"
    aria-labelledby="paymentModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-right">
          <button
            type="button"
            class="btn-close close shadow-none"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-center pb-5">
          <p class="h2">{{ status }}</p>
          <br />
          <p>
            {{ description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
export default {
  name: "PaymentModal",
  components: {},
  data: () => ({
    status: "Platba úspěšně přijata.",
    description:
      "Děkujeme za Vaší objednávku! Voucher jsme vám zaslali na Vaší e-mailovou adresu."
  }),
  methods: {},
  mounted() {
    if (location.hash) {
      // console.log(location.hash)
      const hash = location.hash.split("?")[0];
      // console.log(hash)
      const modal = new Modal(
        document.getElementById("paymentModal"),{backdrop: "static"}
      );
      if (hash == "#paymentSuccessfull") {
        this.status = "Platba úspěšně přijata.";
        this.description =
          "Děkujeme za Vaší objednávku! Voucher jsme vám zaslali na Vaší e-mailovou adresu.";
        modal.show();
      } else if (hash == "#paymentPending") {
        this.status = "Platba se stále zpracovává.";
        this.description = "Jakmile bude platba potvrzena, pošleme Vám e-mail.";
        modal.show();
      } else if (hash == "#paymentCancelled") {
        this.status = "Platba se nezdařila.";
        this.description =
          "Něco se nevydařilo a platba byla zrušena. Prosím proveďte objednávku znova.";
        modal.show();
      }
    }
  }
};
</script>
