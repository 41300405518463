<template>
  <div class="page-header " id="home" data-cd-section="Úvod" style="">

    <div
      class="alert-important alert alert-dismissible text-center fade show"
      role="alert"
    >
         <strong>Máme otevřeno :)</strong> Jsme tu opět pro Vás, přijďte si užít skutečnou zábavu do virtuálního světa.<br />
      <button
        type="button"
        class="btn-close close shadow-none"
        data-dismiss="alert"
        aria-label="Close"
      >
        <!-- <span aria-hidden="true">&times;</span> -->
      </button>
    </div>

    <!-- <div class="filter filter-dark"> -->
      <Particles
        class="filter filter-dark"
        id="tsparticles"
        :options="{
              fpsLimit: 20,
              interactivity: {
                  detectsOn: 'canvas',
                  events: {
                      onClick: {
                          enable: true,
                          mode: 'repulse'
                      },
                      resize: true
                  },
                  modes: {
                      repulse: {
                          distance: 160,
                          duration: 0.5
                      }
                  }
              },
              particles: {
                  color: {
                      value: '#089999',
                  },
                  links: {
                      color: '#059999',
                      distance: 130,
                      enable: true,

                  },
                  move: {
                      direction: 'none',
                      enable: true,
                      outMode: 'out',
                      random: false,
                      speed: 1,
                      straight: false
                  },

                  number: {
                      density: {
                          enable: true,
                          value_area: 400
                      },
                      value: 20
                  },
                  shape: {
                      type: 'circle'
                  },
                  size: {
                      /* random: true, */
                      value: 2
                  }
              },
              detectRetina: true
          }"
      />
    <!-- </div> -->

    <!-- <generalContent :data="data.content[0]" />
    aa -->
    <div class="content-center">
      <div class="container" v-if="data">
        <h1>
          {{ data.content[0].title }} <br />
          <small class="text-white " v-html="data.content[0].text"></small>
        </h1>
      </div>
      <div class="container" v-else>
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
// import generalContent from './content/General.vue'

// import Particles from "particles.vue3";

export default {
  name: "PageTop",
  props: {
    data: Object
  },

  components: {
    // generalContent
    // Particles
  },
  methods: {
    initParticles() {
      // window.particlesJS.load("home", "/data/particles.json");
    }
  },
  created() {
    // let particlesjs = document.createElement("script");
    // particlesjs.setAttribute(
    //   "src",
    //   //"https://www.virty.cz/assets/js/core/particles.min.js"
    //   "/particles.js"
    // );
    // particlesjs.id = "particlesjs";
    // particlesjs.async = true;
    // document.head.appendChild(particlesjs);
  },
  mounted() {
    // document.getElementById("particlesjs").onload = this.initParticles;
  }
};
</script>
