<template>
  <div
    class="modal fade"
    id="reservationModal"
    tabindex="-1"
    aria-labelledby="reservationModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Rezervace</h5>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-center pb-5">
          <div class="row">
            <div class="col-xl-4">
              <h3>1. Vyberte den</h3>
              <!-- <Calendar /> -->
              <DatePicker
                v-model="date"
                :min-date="new Date()"
                :max-date="new Date(Date.now() + 1000 * 60 * 60 * 24 * 28)"
                color="orange"
                is-required
              />
            </div>
            <div class="col-xl-6">
              <h3>2. Zvolte čas</h3>

              <div class="cal-wrap">
                <div class="calendar">
                  <div
                    v-for="(time, i) in times"
                    v-bind:key="i"
                    :class="[
                      'time',

                      {
                        'pointer': !time.disabled,
                        'disabled-time': time.disabled
                      }
                    ]"
                    v-bind="{
                      'data-reserved':
                        !time.after && time.before
                          ? 'start'
                          : !time.after && !time.before
                          ? 'trough'
                          : time.after && !time.before
                          ? 'end'
                          : null
                    }"
                    :data-time="+time.time"
                    :disabled="time.disabled"
                    v-on:pointerdown="time.disabled ? false : send($event)"
                    v-on:pointerover="time.disabled ? false : send($event)"
                  >
                    <span class="number">
                      {{
                        ("0" + time.time.getHours()).slice(-2) +
                          ":" +
                          ("0" + time.time.getMinutes()).slice(-2)
                      }}</span
                    >
                  </div>
                </div>
              </div>

              <div class="row d-flex align-items-end m-3">
                <div
                  v-for="(asset, i) in assets"
                  :key="i"
                  :class="[
                    'col',
                    {
                      'pointer': !asset.disabled,
                      'selected-asset': asset._id == pickedAsset,
                      'disabled-asset': asset.disabled
                    }
                  ]"
                  v-on:click="asset.disabled ? false : setAsset(asset._id)"
                >
                  <img :src="asset.image" /><br />
                  {{ asset.name }}
                </div>
              </div>
            </div>
          </div>

          <h3>3.Rezervujte se</h3>
          <div class="lead m-3" v-if="dates.startDate && dates.endDate">
          Rekapitulace: <b>{{ date.toLocaleDateString() }}</b> ({{ dow[date.getDay()] }}) v <b>{{
            ("0" + dates.startDate.getHours()).slice(-2) +
              ":" +
              ("0" + dates.startDate.getMinutes()).slice(-2)
          }}
          -
          {{
            ("0" + dates.endDate.getHours()).slice(-2) +
              ":" +
              ("0" + dates.endDate.getMinutes()).slice(-2)
          }}</b>
          (<span v-if="reservedTime.hours != 0"
            >{{ reservedTime.hours }} hod.</span>
            <span v-if="reservedTime.hours != 0 && reservedTime.minutes != 0">&nbsp;</span>
          <span v-if="reservedTime.minutes != 0">
            {{ reservedTime.minutes }} min.</span
          >) za  <b>{{ price }}Kč</b>.
        </div>
          <table v-if=false>
            <tr><td class="text-start">
          Den:
        </td><td class="text-end">
          {{ date.toLocaleDateString() }} ({{ dow[date.getDay()] }})
        </td></tr>
        <tr><td class="text-start">
            Čas:
          </td><td class="text-end" v-if="dates.startDate && dates.endDate">
            {{
              ("0" + dates.startDate.getHours()).slice(-2) +
                ":" +
                ("0" + dates.startDate.getMinutes()).slice(-2)
            }}
            -
            {{
              ("0" + dates.endDate.getHours()).slice(-2) +
                ":" +
                ("0" + dates.endDate.getMinutes()).slice(-2)
            }}
            (<span v-if="reservedTime.hours != 0"
              >{{ reservedTime.hours }} hodin<span
                v-if="reservedTime.hours == 1"
                >a</span
              ><span v-else-if="reservedTime.hours < 5">y</span></span
            >
            <span v-if="reservedTime.minutes != 0">
              {{ reservedTime.minutes }} minut</span
            >)</td></tr>

              <tr><td class="text-start">
            Cena:
          </td><td class="text-end"> {{ price + " Kč" }}</td></tr>

        </table>
          <form
            class="row gy-2 gx-3 align-items-center justify-content-center"
            @submit.prevent="makeReservation()"
          >
            <div class="form-floating col-auto">
              <input
                type="text"
                class="form-control"
                v-model="name"
                placeholder="Jméno a Příjmení"
                required
              />
              <label>Jméno a Příjmení</label>
            </div>
            <div class="form-floating col-auto">
              <input
                type="email"
                class="form-control"
                v-model="mail"
                placeholder="E-mail"
                required
              />
              <label>E-mail</label>
            </div>
            <div class="form-floating col-auto">
              <input
                type="text"
                class="form-control"
                v-model="phone"
                placeholder="Telefon"
              />
              <label>Telefon</label>
            </div>
            <div class="col-auto">
              <button
                type="submit"
                class="btn btn-success btn-lg"
                v-bind:disabled="dates.startDate == null || pickedAsset == null"
              >
                Rezervovat!
              </button>
            </div>
          </form>

          <!-- <form class="row p-4">
            <label for="input-name">Jméno</label>
            <input class="form-control mt-1 mb-1 col" placeholder="Jméno Příjmení" name="name" id="input-name" v-model="name" required>
              <label for="input-email">E-Mail</label>
            <input class="form-control mt-1 mb-1 col" placeholder="e-mail" name="email" id="input-email" v-model="email"
              type="email" required>

            <input class="form-control mt-1 mb-1 col" placeholder="telefon" name="phone" v-model="phone" >
          <button
          type="submit"
            class="btn btn-success"
            v-bind:disabled="dates.startDate == null"
            v-on:click="makeReservation()"
          >
            Rezervovat!
          </button>

        </form> -->
          <!-- <div v-if="bookings">
            <div v-for="(booking, i) in bookings" v-bind:key="i">
              {{ booking.bookingStart }}
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="reservationResultModal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ resultStatus }}</h5>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-center pb-5">
          <p>
            {{ resultDescription }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { DatePicker } from "v-calendar";

import { Modal } from "bootstrap";

export default {
  name: "PaymentModal",
  components: {
    DatePicker
  },
  setup() {
    const store = useStore();
    const isOpen = computed(() => store.state.reservationModal);
    function close() {
      store.commit("closeReservations");
    }
    // this.price = price;
    return { isOpen, close };
  },
  data: () => ({
    dow: ["neděle", "pondělí", "úterý", "středa", "čtvrtek", "pátek", "sobota"],
    openHour: 14,
    closeHour: 22,
    slotTime: 0.5,
    pickedAsset: null,
    name: "",
    mail: "",
    phone: "",
    assets: [
      // {
      //   id: "asdf",
      //   name: "HTC Vive Pro",
      //   image:
      //     "https://sqcms.zionbit.cz/api/assets/virty-www/b1908d4a-03df-473d-9e9c-b9485487f18f?width=130",
      //   prices: ""
      // },
      // {
      //   id: "yxvc",
      //   name: "HTC Vive Pro",
      //   image:
      //     "https://sqcms.zionbit.cz/api/assets/virty-www/b1908d4a-03df-473d-9e9c-b9485487f18f?width=130",
      //   prices: ""
      // },
      // {
      //   id: "yxvcx",
      //   name: "KAT Walk Mini",
      //   image:
      //     "https://sqcms.zionbit.cz/api/assets/virty-www/9b1c8e0c-034c-4c89-aa48-0a15a80dc617?width=130",
      //   prices: ""
      // },
      // {
      //   id: "yxvcas",
      //   name: "KAT Walk Mini",
      //   image:
      //     "https://sqcms.zionbit.cz/api/assets/virty-www/9b1c8e0c-034c-4c89-aa48-0a15a80dc617?width=130",
      //   prices: ""
      // },
      // {
      //   id: "yxvcasaa",
      //   name: "Oculus Rift S",
      //   image:
      //     "https://sqcms.zionbit.cz/api/assets/virty-www/742811ce-a50e-4021-82d2-29495e93820d?width=130",
      //   prices: ""
      // }
    ],
    reservedTime: 0,
    price: 0,
    times: [],
    date: new Date(new Date().setHours(0, 0, 0, 0)),
    dates: {
      firstDate: null,
      secondDate: null,
      startDate: null,
      endDate: null,
      maxTime: null,
      minTime: null
    },
    bookings: [],
    modal: null,
    machine: {},
    pickerCurrentState: "idle",
    resultStatus: null,
    resultDescription: null
  }),

  watch: {
    isOpen: function(val) {
      if (val) {
        this.modal.show();
      } else {
        this.modal.hide();
      }
    },
    date: function() {
      // console.log("CHANGE!!!")
      // console.log(this.date)
      if (this.date == null) {
        // date.
        // console.log("Ignore")
        return false;
      }
      // console.log("happening?")
      this.getBookings(this.date);
      if (this.dates.startDate != null) {
        this.dates.startDate.setMonth(
          this.date.getMonth(),
          this.date.getDate()
        );
        this.date.firstDate = +this.dates.startDate;
      }
      if (this.dates.endDate != null) {
        this.dates.endDate.setMonth(this.date.getMonth(), this.date.getDate());

        this.date.secondDate = +this.dates.endDate;
      }

      this.setupSlots();

      this.$nextTick(function() {
        this.updateDOM();
        this.freeAssets();
      });
    }
  },
  methods: {
    closeReservations() {
      this.close();
    },
    setupPicker() {
      this.machine = {
        states: {
          idle: {
            on: {
              pointerdown: target => {
                this.dates.firstDate = target.dataset.time;
                this.dates.secondDate =
                  typeof target.nextSibling.dataset != "undefined" &&
                  target.nextSibling.attributes.disabled.value == "false" &&
                  target.nextSibling.dataset.reserved != "end"
                    ? target.nextSibling.dataset.time
                    : target.previousSibling.dataset.time;
                // document.body.addEventListener("pointerdown", this.send);
                this.dates.minTime = this.getMinTime(target);
                this.dates.maxTime = this.getMaxTime(target);
                document.body.addEventListener("pointerup", this.send);
                return "beforeDragging";
              }
            }
          },
          beforeDragging: {
            on: {
              pointerup: () => {
                // console.log("adding everywhere")
                document.body.addEventListener("pointerdown", this.send);
                return "dragging";
              }
            }
          },
          dragging: {
            on: {
              pointerover: target => {
                // console.log("pointerover");
                // console.log(target.dataset.time);
                // console.log(this.dates.minTime);
                // console.log(this.dates.maxTime);
                if (target.dataset.time != this.dates.firstDate) {
                  if (
                    target.dataset.time <= this.dates.maxTime &&
                    target.dataset.time >= this.dates.minTime
                  ) {
                    this.dates.secondDate = target.dataset.time;
                  }
                }

                return "dragging";
              },
              pointerdown: "afterDragging",
              pointercancel: "idle"
            }
          },
          afterDragging: {
            on: {
              pointerup: () => {
                document.body.removeEventListener("pointerup", this.send);
                document.body.removeEventListener("pointerdown", this.send);
                this.freeAssets();
                return "idle";
              }
            }
          }
        }
      };
    },
    send(event) {
      // console.log("SEND");
      // console.log(event.type);
      // console.log(this.pickerCurrentState);
      const target = event.currentTarget;
      const transition = this.machine.states[this.pickerCurrentState].on[
        event.type
      ];

      if (typeof transition === "function") {
        this.pickerCurrentState = transition(target);
        this.setDates();
        this.updateDOM();
      } else if (transition) {
        this.pickerCurrentState = transition;
        this.setDates();
        this.updateDOM();
      }
    },
    setDates() {
      const startDate = Math.min(this.dates.firstDate, this.dates.secondDate);
      const endDate = Math.max(this.dates.firstDate, this.dates.secondDate);

      this.dates.startDate = new Date(startDate);
      // }
      //
      // if (endDate) {
      //   const endDateEl = document.querySelector(`[data-time="${endDate}"]`);
      //   endDateEl.dataset.selected = "end";
      this.dates.endDate = new Date(endDate);

      this.getReservedTime();
      this.getPrice();
    },
    updateDOM() {
      // console.log("updateDOM")
      // document.querySelectorAll("[data-reserved='trough']").forEach(el => {
      //   if(el.dataset.selected) {
      //     console.log("FOUND colision")
      //     console.log(el)
      //     this.dates.startDate = null;
      //     this.dates.endDate = null;
      //     this.dates.firstDate = null;
      //     this.dates.secondDate = null;
      //     // this.updateDOM();
      //   }
      // })
      // document.querySelectorAll("[data-selected='trough']").forEach(el => {
      //   if(el.dataset.reserved) {
      //     console.log("FOUND colision")
      //     console.log(el)
      //     this.dates.startDate = null;
      //     this.dates.endDate = null;
      //     this.dates.firstDate = null;
      //     this.dates.secondDate = null;
      //     // this.updateDOM();
      //   }
      // })

      if (this.dates.startDate < new Date()){
        this.dates.startDate = null;
        this.dates.endDate = null;
      }
      if (this.dates.startDate == null || this.dates.endDate == null) {
        document.querySelectorAll(".time").forEach(el => {
          delete el.dataset.selected;
        });
        return false;
      } else {

      document.querySelectorAll(".time").forEach(el => {
        delete el.dataset.selected;
        // console.log(el.dataset.time);
        // console.log("vs");
        // console.log(+this.dates.startDate);
        if (el.dataset.time == +this.dates.startDate) {
          el.dataset.selected = "start";
        } else if (
          el.dataset.time > +this.dates.startDate &&
          el.dataset.time < +this.dates.endDate
        ) {
          el.dataset.selected = "trough";
        } else if (el.dataset.time == +this.dates.endDate) {
          el.dataset.selected = "end";
        }
      });
    }

      // if (startDate) {
      //   const startDateEl = document.querySelector(
      //     `[data-time="${startDate}"]`
      //   );
      //   startDateEl.dataset.selected = "start";
      //
      // }
    },
    getMinTime(elem) {
      const selector = "[data-reserved='end']";

      if (!elem.previousElementSibling || elem.dataset.reserved == "end") {
        return elem.dataset.time;
      }

      var sibling = elem.previousSibling;

      while (sibling) {
        if (sibling.matches(selector)) return sibling.dataset.time;
        if (!sibling.previousElementSibling) {
          return sibling.dataset.time;
        }
        sibling = sibling.previousElementSibling;
      }
    },
    getMaxTime(elem) {
      const selector = "[data-reserved='start']";
      // Get the next sibling element
      if (!elem.nextElementSibling || elem.dataset.reserved == "start") {
        return elem.dataset.time;
      }

      var sibling = elem.nextElementSibling;

      // If the sibling matches our selector, use it
      // If not, jump to the next sibling and continue the loop
      while (sibling) {
        if (sibling.matches(selector)) return sibling.dataset.time;
        if (!sibling.nextElementSibling) {
          return sibling.dataset.time;
        }
        sibling = sibling.nextElementSibling;
      }
    },
    setupSlots() {
      // console.log("setupSlots")
      const count = (this.closeHour - this.openHour) / this.slotTime + 1;
      let i;
      for (i = 0; i < count; i++) {
        const time = new Date(
          +this.date + (this.openHour + i * this.slotTime) * 36e5
        );
        let before = true;
        let after = true;
        // if (this.bookings)
        this.bookings.forEach(x => {
          if (x.asset != this.pickedAsset) {
            return;
          }
          const start = new Date(x.bookingStart);
          const end = new Date(x.bookingEnd);
          // if (+this.dates.startDate >= +start && +this.dates.startDate < +end) {
          //   // console.log("COLISION");
          //   this.dates.startDate = null;
          //   this.dates.endDate = null;
          // }
          // if (+this.dates.endDate <= +end && +this.dates.endDate > +start) {
          //   // console.log("COLISION");
          //   this.dates.startDate = null;
          //   this.dates.endDate = null;
          // }
          //
          if (!(+this.dates.startDate >= +end || +this.dates.endDate <= +start)) {

            this.dates.startDate = null;
            this.dates.endDate = null;
            // this.assets[x.asset].disabled = true;
          }
          // console.log(time);
          // console.log(start);
          // console.log(end);
          if (+time == +start) {
            after = false;
          } else if (+time == +end) {
            before = false;
          } else if (+time > +start && +time < +end) {
            after = false;
            before = false;
          }
        });

        const disabled =
          time < new Date() ||
          (!after && !before) ||
          (!after && i == 0) ||
          (!before && i == count - 1) ||
          (i == count - 1 && this.times[i - 1].disabled);

        this.times[i] = {
          time: time,
          before: before,
          after: after,
          disabled: disabled
        };
      }
    },
    freeAssets() {
      // console.log(this.bookings);
      let disabled = [];
      this.bookings.forEach(x => {
        const start = new Date(x.bookingStart);
        const end = new Date(x.bookingEnd);
        if (!(+this.dates.startDate >= +end || +this.dates.endDate <= +start)) {
          // console.log(`Asset ${x.asset} disable`);
          disabled.push(x.asset);
          // this.assets[x.asset].disabled = true;
        }
        // if (+this.dates.startDate < +end && +this.dates.startDate >= +start &&) {
        //   console.log(`Asset ${x.asset} disable`)
        // }
        // if (+this.dates.endDate <= +end && +this.dates.endDate > +start) {
        //   console.log(`Asset ${x.asset} disable`)
        // }
      });

      this.assets.forEach(a => {
        if (disabled.includes(a._id)) {
          a.disabled = true;
        } else {
          a.disabled = false;
        }
      });
      // console.log(this.dates.startDate);
      // console.log(this.dates.endDate);
    },
    setAsset(asset) {
      this.pickedAsset = asset;

      this.setupSlots();
      this.$nextTick(function() {
        this.updateDOM();
      });
    },

    getReservedTime() {
      const minutes = (+this.dates.endDate - +this.dates.startDate) / 6e4;
      this.reservedTime = {
        hours: Math.floor(minutes / 60),
        minutes: minutes % 60
      };
    },
    getPrice() {
      this.price =
        this.reservedTime.hours * 500 + (this.reservedTime.minutes / 30) * 270;
    },
    getBookings(date = null) {
      if (date == null) date = new Date();
      const x = `${date.getFullYear()}-${date.getMonth() +
        1}-${date.getDate()}`;
      // console.log(x);
      const url = new URL(process.env.VUE_APP_BOOKING_URL+"/getBook");
      const params = new URLSearchParams();
      params.append("day", x);
      // params.append("asset", this.pickedAsset);
      // console.log("fetching booking");
      url.search = params;
      fetch(url, {
        method: "GET"
      })
        .then(response => response.json())
        .then(data => (this.bookings = data))
        .then(() => {
          this.setupSlots();
          this.freeAssets();
          this.updateDOM();
        });
      // if(response)
      // console.log("XXXXXXXXXXX")
      // console.log(response)
      // this.bookings = JSON.parse(`'${response}'`)
    },
    getAssets() {
      const url = new URL(process.env.VUE_APP_BOOKING_URL+"/getAssets");
      fetch(url, {
        method: "GET"
      })
        .then(response => response.json())
        .then(data => (this.assets = data));
    },
    // disable() {
    //   this.bookings.forEach(x => console.log(x.bookingStart));
    // },
    async makeReservation() {
      const sendData = {
        asset: this.pickedAsset,
        mail: this.mail,
        name: this.name,
        phone: this.phone,
        startTime: this.dates.startDate,
        endTime: this.dates.endDate
      };
      // console.log(sendData);
      const response = await fetch(process.env.VUE_APP_BOOKING_URL+"/reserve", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(sendData)
      });
      if(response.status == 200){
        console.log("OKKK")

        this.resultStatus = "Rezervováno"
        this.resultDescription = "Rezervace proběhla úspěšně, během chvilky vám dorazí potvrzovací e-mail."

      }else{

        this.resultStatus = "Chyba"
        this.resultDescription = "Během rezervace došlo k chybě. Zkuste to prosím za chvilku znova."

      }

      this.modal.hide();
      this.resultModal.show();

      console.log(response);
    }
  },
  mounted() {
    this.setupPicker();
    this.getAssets();
    this.getBookings();
    this.setupSlots();
    this.modal = new Modal(document.getElementById("reservationModal"), {
      backdrop: "static",
      keyboard: true
    });
    this.resultModal = new Modal(document.getElementById("reservationResultModal"), {
      keyboard: true
    });

    document.getElementById("reservationModal").addEventListener(
      "hide.bs.modal",
      function() {
        this.close();
      }.bind(this)
    );

    document
      .getElementById("reservationModal")
      .addEventListener("show.bs.modal", function() {
        // console.log("Hook open");
        //
        //
        /* ---------------------------------- */
      });
  }
};
</script>
