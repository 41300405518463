<template>
  <main>
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <Navigation />
    <PageTop :data="apiData.home" />
    <Page :data="apiData.about" />
    <Page :data="apiData.games" />
    <Page :data="apiData.equipment" />
    <Vouchers :data="apiData.vouchers" />
    <Page :data="apiData.events" />
    <Page :data="apiData.faq" />
    <Page :data="apiData.rental" />
    <Contact :data="apiData.contact" />
    <Footer />
    <GameModal />
    <PaymentModal />
    <ReservationModal />
  </main>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import PageTop from "./components/PageTop.vue";
import Page from "./components/Page.vue";
// import Games from "./components/Games.vue";
import Vouchers from "./components/Vouchers.vue";
import Contact from "./components/Contact.vue";
import GameModal from "./components/GameModal.vue";
import PaymentModal from "./components/PaymentModal.vue";
import ReservationModal from "./components/ReservationModal.vue";
import Footer from "./components/Footer.vue";

// import "bootstrap/scss/bootstrap.scss";
import "./assets/scss/bootstrap.scss";
import "./assets/scss/paper-kit.scss";
import "./assets/scss/virty.scss";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { getData } from "./service";

export default {
  name: "App",
  components: {
    Navigation,
    GameModal,
    PaymentModal,
    ReservationModal,
    PageTop,
    Page,
    // Games,
    Vouchers,
    Contact,
    Footer
  },
  data: () => ({
    gamedata: null,
    aboutdata: null,
    apiData: { games: null },
    datay: { games: null },
    api: null,
    navbarMenuVisible: 0
  }),
  watch: {
    apiData() {
      // console.log("WATCHED!");
      if (this.apiData) {
        // console.log(location.hash);
        // console.log(document.getElementById("games"));
      }
    }
  },
  methods: {
    loadData3: async function(content) {
      try {
        const result = await getData(content, localStorage.getItem("lang"));
        this.page = result;
        this.page.forEach(item => {
          this.apiData[item.id] = item;
        });
      } catch (ex) {
        this.aboutdata = null;
      }
      this.loaded();
    },
    loaded() {
      this.$nextTick(function() {
        if (this.apiData && location.hash) {
          setTimeout(function() {
            // console.log(location.hash)
            // location.hash.split('?')[0];

            const hash = location.hash.split("?")[0];
            if (document.querySelector(hash)) {
              // id = (document.querySelector(location.hash).split('?')[0]
              document.querySelector(hash).scrollIntoView({
                behavior: "smooth"
              });
            }
          }, 300);
        }
      });
    },
    smoothScroll(e) {
      e.preventDefault();
      let href = "";
      if (e.target.matches("a")) {
        href = e.target.getAttribute("href");
      } else {
        href = e.target.parentNode.getAttribute("href");
      }
      // const target = e.target;
      window.history.pushState("", "", href);
      document.querySelector(href).scrollIntoView({
        behavior: "smooth"
      });
    },
    navbarMenuClose() {
      if (this.navbarMenuVisible == 1) {
        this.navbarMenuVisible = 0;
        document.querySelector("html").classList.remove("nav-open");
        document.querySelector("#bodyClick").remove();
        document.querySelector(".navbar-brand").classList.remove("moved");
        setTimeout(function() {
          document.querySelector(".navbar-toggler").classList.remove("toggled");
        }, 300);
      }
    },
    navbarMenuOpen() {
      this.navbarMenuVisible = 1;
      document.querySelector("html").classList.add("nav-open");
      document.querySelector(".navbar-brand").classList.add("moved");
      // document.querySelector('.navbar-brand').animate({'margin-left': '235px'}, 300);
      setTimeout(function() {
        document.querySelector(".navbar-toggler").classList.add("toggled");
      }, 300);
      const div = document.createElement("div");
      div.id = "bodyClick";
      document.querySelector("body").appendChild(div);
      document
        .querySelector("#bodyClick")
        .addEventListener("click", this.navbarMenuClose);
    },
    navbarToggle() {
      if (this.navbarMenuVisible == 0) {
        this.navbarMenuOpen();
      } else {
        this.navbarMenuClose();
      }
    }
    // loadGrapql: async function() {
    //   this.api = await gqlQuery(
    //     "queryPageContents",
    //     `{queryPageContents {
    //         data{
    //           id{
    //             iv
    //           }
    //           title{
    //             ${this.$lang}
    //           }
    //           slug{
    //             ${this.$lang}
    //           }
    //           content{
    //             iv {
    //               __typename
    //               data{
    //                 text {
    //                   ${this.$lang}
    //                 }
    //                 title {
    //                   ${this.$lang}
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }}`,
    //     this.$lang
    //   );
    //   // console.log(this.api)
    //   // console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXx")
    //   // console.log("AAAAA",this.api.data)
    //   this.api.forEach(item => {
    //     // console.log(item.data.id)
    //     this.apiData[item.data.id] = item.data;
    //     // console.log("done")
    //   });
    //   // console.log("FINAL A: ")
    //   // console.log(this.apiData)
    // }
  },
  // pages: {queryPageContents {
  // data{
  //   title {
  //     cs
  //   }
  //   slug{
  //     cs
  //   }
  // }
  // }}
  created() {
    // this.loadData('top-games')
    // this.loadData2('about-us')
    this.loadData3("page");
    // this.loadGrapql()
    // this.$lang = "cs"

    //
    // document.addEventListener('click', function (event) {
    //
    // // If the clicked element doesn't have the right selector, bail
    // console.log(event.target)
    // if (!event.target.matches('.navbar-brand, .nav-link')) return;
    //
    // // Don't follow the link
    // event.preventDefault();
    //
    // // Log the clicked element in the console
    // console.log(event.target.getAttribute("href"));
    //
    // const href = event.target.getAttribute("href");
    //
    //   window.history.pushState('', '', event.target.getAttribute("href"));
    //   document.querySelector(href).scrollIntoView({
    //     behavior: "smooth"
    //   });
    //
    // // document.querySelector('body,html').animate({
    // //     'scrollTop': document.querySelector(location.hash)
    // //   },
    // //   600
    // // );
    // //this.smoothScroll();
    //
    // }, false);
  },
  mounted() {
    const links = document.querySelectorAll(".navbar-brand, .nav-link");
    // console.log(links)
    for (const link of links) {
      // link.addEventListener("click", this.smoothScroll);
      link.addEventListener("click", this.navbarMenuClose);
      // console.log(link)
    }
    document
      .querySelector(".navbar-toggler")
      .addEventListener("click", this.navbarToggle);
    // window.addEventListener('popstate', function(){
    //   console.log("history!")
    // });

    //    document.querySelector('.nav-link').on('click', function(event) {
    //      event.preventDefault();
    //      this.smoothScroll(this.hash);
    //    });
  }
};
</script>
