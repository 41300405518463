import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import VueEasyLightBox from "vue-easy-lightbox";
import Particles from "particles.vue3";

// import router from "./router";
const app = createApp(App).use(store).use(VueEasyLightBox).use(Particles);

// Vue.config.productionTip = false
// app.prototype.$lang = 'cs';

/// LANGUGAGE SET ///
let lang = ""
if (
  process.env.VUE_APP_LANGS.split(",").includes(
    window.location.pathname.split("/")[1]
  )
) {
  //override with language in URL if valid
  lang = window.location.pathname.split("/")[1];
  localStorage.setItem("lang", lang);
} else if (localStorage.getItem("lang")) {
  // or is alredy set in local storage
  lang = localStorage.getItem("lang");
} else {
  lang = process.env.VUE_APP_DEFAULT_LANG;
  localStorage.setItem("lang", lang);
}
/// END OF LANGUGAGE SET  ///

// app.config.globalProperties.$lang = "cs";
app.config.globalProperties.$assetsUrl = process.env.VUE_APP_ASSETS_URL;

app.mount("#app");
