<template v-if="data">
    <gameContent v-if="data.schemaName=='games'" :data="data" />

    <textContent v-else-if="data.schemaName=='texts'" :data="data" />

    <voucherContent v-else-if="data.schemaName=='voucher'" :data="data" />

    <div class="row" v-else-if="data.schemaName=='group'">
        <generalContent v-for="(item, i) in data.content" :data="item" v-bind:key="i"/>
    </div>
</template>


<script>

import textContent from './Text.vue'
import gameContent from './Game.vue'
import voucherContent from './Voucher.vue'
import generalContent from './General.vue'


export default {
  name: 'GeneralContent',
  components: {
    textContent,
    gameContent,
    voucherContent,
    generalContent
  },
  props: {
    data: Object
  },
  methods:{
    log(msg){
      console.log(msg)
    }
  }

}
</script>
