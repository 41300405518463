<template>
  <div v-if="data" class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card card-game" :id="data.id" data-game="game">
      <div class="card-img-top">
        <a
          v-on:click="loadGameData(data.cmsid)"
        >
          <picture>
            <img
              :src="$assetsUrl + data.Image[0] + '?width=420&height=240'"
              :alt="data.Title"
            />
          </picture>
        </a>
      </div>
      <div class="card-body">
        <h4 class="card-title">{{ data.Title }}</h4>
        <p class="card-description">
          {{ truncate(data.Description, 140) }}
          &nbsp;<a
            class="card-link"
            v-on:click="loadGameData(data.cmsid)"
            >více</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { truncate } from "../../service/filters";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "gameContent",
  props: {
    data: Object
  },
  data: () => ({
    lang: "cs"
  }),
  methods: {
    truncate
  },
  setup() {
    const store = useStore();
    const gameData = computed(() => store.state.gameData);
    function loadGameData(id) {
      if (gameData.value.cmsid != id) {
        store.commit("openGameModal");
        store.commit("loadGameData", id);
      }
    }
    // this.price = price;
    return { loadGameData, gameData };
  }
};
</script>
