<template v-if="data">

  <div v-if="data.style==='top'" class="m-auto col-md-10 text-center section-header">
    <h2 class="title">{{ data.title }}</h2>
    <p class="description" v-html="data.text"></p>
  </div>

  <div v-else-if="data.style==='widget'" class="col-sm-6 m-auto d-flex align-items-center">
    <div class="info">
      <div v-if="data.icon" class="icon-gold">
        <i class="icon material-icons">{{ data.icon }}</i>
      </div>
      <div v-else-if="data.image" class="card card-plain">
        <div class="card-img-top">
          <img :src="$assetsUrl + data.image[0] + '?width=445'" :alt="data.title">
        </div>
      </div>
      <div class="description">
        <h4 class="info-title">{{ data.title }}</h4>
        <p class="description" v-html="data.text"></p>
      </div>
    </div>
  </div>

  <div v-else-if="data.style==='faq'" class="faq mx-2">
    <h3>{{ data.title }}</h3>
    <p v-html="data.text"></p>
  </div>

  <div v-else-if="data.style==='narrow'" class="col-md-8 m-auto text-center section-header">
    <h2 class="title">{{ data.title }}</h2>
    <p class="description" v-html="data.text"></p>
  </div>

  <div v-else class="">
    <h2 class="title">{{ data.title }}</h2>
    <p class="description" v-html="data.text"></p>
  </div>

</template>

<script>
export default {
  name: 'textContent',
  props: {
    data: Object
  }
}
</script>
