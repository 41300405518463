<template>
  <footer class="footer footer-black">
    <div class="container">
      <div class="row">
        <nav class="footer-nav">
          <ul>
            <li>
              <a href="https://sqcms.zionbit.cz/api/assets/virty-www/442176db-9166-4d23-b6e8-267b22ecd5f6/zpracovavani-osobnich-udaju.pdf?sq=1a4e95cf-e788-2e64-716e-11a02501389f" target="_blank"
                >Ochrana osobních údajů</a
              >
            </li>
            <li>
              <a href="https://sqcms.zionbit.cz/api/assets/virty-www/ddfdbd97-e06e-4e17-af8f-002dac1d00b3/obchodni-podminky-voucher.pdf?sq=edece675-7e82-15e7-f791-afac00bb4b3b" target="_blank"
                >Obchodní podmínky</a
              >
            </li>
            <li>
              <a href="https://sqcms.zionbit.cz/api/assets/virty-www/8addbc2c-6557-4298-9eaa-a06d21d099da/provozni-rad.pdf?sq=e9a906ee-8c06-d3b6-c549-cfcf4d5f3cf3" target="_blank"
                >Provozní řád</a
              >
            </li>
          </ul>
        </nav>
        <!-- <div class="credits ml-auto">Naposledy aktualizováno 17.11.2020</div> -->
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6 br-1">
          Provozovatel:
          <ul class="pl-0">
            <li>
              <!-- <a target="_blank" href="https://www.zionbit.cz"><img width="100" src="assets/img/external/zionbit-logo-md-white.png"></a> -->
              <img
                width="100"
                src="../assets/img/external/zionbit-logo-md-white.png"
                alt="logo zionbit"
              />
            </li>
          </ul>
        </div>
        <div class="col-md-4 col-sm-6">
          Najdete nás na:
          <ul class="pl-0">
            <li>
              <a
                target="_blank"
                href="https://www.kudyznudy.cz/?utm_source=kzn&utm_medium=partneri_kzn&utm_campaign=banner"
                ><img
                  width="100"
                  src="../assets/img/external/kudyznudy_logo.png"
                  alt="logo kudy z nudy"
              /></a>
            </li>

            <li>
              <a target="_blank" href="https://www.facebook.com/virty.cz/"
                ><img
                  width="100"
                  src="../assets/img/external/facebook-white.png"
                  alt="logo facebook"
              /></a>
            </li>
          </ul>
        </div>

        <!-- <div class="col-md-3 col-sm-4">
              <br>
            <ul class="pl-0">
            </ul>
            </div> -->
      </div>
    </div>
  </footer>
</template>

<script>
// import textContent from './content/Text.vue'

export default {
  name: "Faq",
  components: {
    // textContent
  },
  props: {
    data: Object
  },
  data: function() {
    return {
      items: [
        {
          icon: "flash_on",
          title: "Super vybavení",
          description:
            "U nás v herně používáme moderní zařízení jako jsou VR brýle HTC Vive Pro a Oculus Rift S či běhací platformu Kat Walk Mini od KatVR. K tomu samozřejmě nechybí výkonné počítače zajišťující co nejlepší požitek ze hry a 4k televize, ať si mouhou zábavu užít také přihlížející.",
          link: "#asd"
        },
        {
          icon: "videogame_asset",
          title: "Široká nabídka her",
          description:
            "Nabízíme velmi široké portfolio zábavných titulů od stříleček přes arkádovky až po interaktivní videa.",
          link: "#asd"
        },
        {
          icon: "fastfood",
          title: "Občerstvení",
          description:
            "Pokud dostanete na něco chuť, koupíte si u nás i drobné občerstvení. Ať už pivo, minerálku nebo něco k zakousnutí.",
          link: "#asd"
        },
        {
          icon: "star",
          title: "Služby pro Vás",
          description:
            "Nabízíme vám například Wi-Fi zdarma, fotky z akce, či možnost přihlášení ke svému Steam účtu (ať si můžete zahrát VR hry, které zde nemáme, hrát se svou vlastní postavou a nasbírat si achivementy).",
          link: "#asd"
        }
      ]
    };
  }
};
</script>
