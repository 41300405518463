<template>
  <nav class="navbar navbar-expand-lg sticky-top navbar-dark vty">
    <div class="container-xl container-fluid">
      <div class="navbar-translate">
        <a
          class="navbar-brand"
          href="#app"
          rel="tooltip"
          title="VIRTY"
          data-placement="bottom"
        >
          <img src="../assets/img/logo/virty-logo.svg" alt="logo virty" />
        </a>

        <button
          class="navbar-toggler shadow-none"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-controls="navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="navigation">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item" v-for="item in menuItems" v-bind:key="item.href">
            <a :href="item.href" class="nav-link"> {{ item.title }}</a>
          </li>
          <li class="nav-item">
            <button
              class="btn-reserve btn btn-sm btn-round btn-gold btn-t-dark"
              v-on:click="Reservations()"
            > Rezervace
          </button>
          </li>
          <li class="nav-item">
            <a
              target="_blank"
              href="https://www.facebook.com/virty.cz/"
              class="btn-facebook"
              ><i class="icon material-icons">facebook</i></a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>

import { useStore } from "vuex";


export default {
  name: "Navigation",
  setup() {
    const store = useStore();
    function openReservations() {
      store.commit("openReservations");
    }
    // this.price = price;
    return { openReservations };
  },
  data: function() {
    return {
      menuItems: [
        {
          href: "#about",
          title: "O nás"
        },
        {
          href: "#games",
          // title: 'Výběr her'
          title: "hry"
        },
        {
          href: "#equipment",
          title: "Vybavení"
        },
        {
          href: "#vouchers",
          title: "Vouchery"
        },
        {
          href: "#events",
          title: "Akce"
        },
        {
          href: "#faq",
          title: "FAQ"
        },
        {
          href: "#rental",
          title: "Půjčovna"
        },
        {
          href: "#contact",
          title: "Kontakt"
        }
      ]
    };
  },
  methods: {
    Reservations(){
      this.openReservations();
      console.log('Opened!!!')
    }
  }
};
</script>
