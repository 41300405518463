<template>
  <div :class="'section section-'+data.sectionType+' section-'+data.id" v-if="data" :id="data.id" :data-cd-section="data.title">
    <div :class="'container' + (data.class ? ' '+data.class : '')">
      <generalContent v-for="(item, i) in data.content" :data="item" v-bind:key="i" />
    </div>
  </div>
</template>

<script>
import generalContent from './content/General.vue'


export default {
  name: 'Page',
  components: {
    generalContent
  },
  props: {
    data: Object
  },

}
</script>
