<template v-if="data">
  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
    <div class="card card-pricing">
      <div class="card-body">
        <h6 class="text-center">{{ data.title }}</h6>
        <picture class="pointer">
          <img
            :src="$assetsUrl + data.image[0] + '?'"
            :alt="data.title"
            @click="this.show(0)"
          />
        </picture>
        <div class="row justify-content-between">
          <div class="col-auto text-left card-price" v-html="data.priceText"></div>
          <div class="col-auto text-right">
            <div :class="'btn btn-round btn-' + data.color + '  voucher p-0'">
              <span class="input-control" v-on:click="decrease">-</span>
              <input
                type="text"
                pattern="\d+"
                maxlength="2"
                class="voucher"
                :name="data.id"
                :id="data.id"
                :data-price="data.price"
                v-model="count"
                min="0"
                max="99"
                @focus="$event.target.select()"
                required
              />
              <span class="input-control" v-on:click="increase">+</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <vue-easy-lightbox
    moveDisabled
    :visible="visible"
    :index="index"
    :imgs="imgs"
    @hide="hide"
  >
  </vue-easy-lightbox>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "voucherContent",
  props: {
    data: Object
  },
  data: () => ({
    price: 0,
    count: 0,
    visible: false,
    index: 0,
    imgs: null
  }),
  setup() {
    const store = useStore();
    function updatePrice() {
      store.commit("updatePrice", this.price);
    }
    // this.price = price;
    return { updatePrice };
  },
  created() {
    this.imgs = this.data.image.map(x => this.$assetsUrl + x);
  },
  methods: {
    show(i) {
      this.index = i;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    increase() {
      if (this.count < 99) {
        this.count++;
      }
    },
    decrease() {
      if (this.count > 0) {
        this.count--;
      }
    },
    calcPrice() {
      this.price = 0;

        const inputs = document.querySelectorAll("input.voucher");
      for (const input of inputs) {
        this.price += input.value * input.getAttribute("data-price");
      }
      this.updatePrice();
      // this.$parent.calcPrice();
    }
  },
  watch: {
    count() {
      this.$nextTick(function () {
        this.calcPrice()
      })
    }
  }
};
</script>
