const CONFIG = {
    url: process.env.VUE_APP_API_ENDPOINT,
    appName: process.env.VUE_APP_NAME,
    clientId: process.env.VUE_APP_CLIENT_ID,
    clientSecret: process.env.VUE_APP_CLIENT_SECRET
};


////////  Bearer Token manupulation
function getBearerToken() {
    return localStorage.getItem('token');
}

function setBearerToken(token) {
    localStorage.setItem('token', token);
}

function clearBearerToken() {
    localStorage.removeItem('token');
}

export async function fetchBearerToken() {
    // Check if we have already a bearer token in local store.
    let token = getBearerToken();

    if (token) {
        return token;
    }

    const params = new URLSearchParams('grant_type=client_credentials&scope=squidex-api');
    params.append('client_id', CONFIG.clientId);
    params.append('client_secret', CONFIG.clientSecret);

    // Get the bearer token. Ensure that we use a client id with readonly permissions.
    const response = await fetch(buildUrl('identity-server/connect/token'),
     {
       method: 'POST',
       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
       body: params.toString()
     }
     );
    if (!response.ok) {
        throw new Error(`Failed to retrieve token, got ${response.statusText}`);
    }

    const json = await response.json();

    token = json.access_token;

    // Cache the bearer token in the local store.
    setBearerToken(token);

    return token;
}








////////  Internal functions



function buildUrl(url) {
    if (url.length > 0 && url.startsWith('/')) {
        url = url.substr(1);
    }

    const result = `${CONFIG.url}/${url}`;

    return result;
}


async function getContent(url, lang) {
  // console.log("getContent called with URL: "+url)
    return await getContentInternal(url, true, lang);
}


async function getContentInternal(url, retry, lang) {

    // console.log("getContentInternal called with URL: "+url)
    // Fetch the bearer token.
    const token = await fetchBearerToken();

    const response = await fetch(buildUrl(url), {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'X-Languages': lang ? lang : ''
        }
    });
    // console.log("getContentInternal response: ")
    // console.log(response)
    if (!response.ok) {
        if (response.status === 404 || response.status === 403 || response.status === 401) {
            // If we get an error we clear the bearer token and retry the request.
            clearBearerToken();

            if (retry) {
                return getContentInternal(url);
            }
        }

        throw new Error(`Failed to retrieve content, got ${response.statusText}`);
    }

    return await response.json();
}




///////  Exported functions




export async function getContentId(contentId, lang) {
    return await getContent(`api/content/${CONFIG.appName}?ids=${contentId}`,lang);
}


export async function getDataByID(contentId, lang) {
    const content = await getContentId(contentId, lang);

    let promises = content.items.map(g => parseContent(g, lang));
    let parsedData = {}
    await Promise.all(promises).then(results => {
      parsedData = results[0];
      // console.log("RESULT2")
      // console.log(parsedData["content"])
    });

    return parsedData;
}



export async function getData(contentName, lang) {
    const json = await getContent(`api/content/${CONFIG.appName}/${contentName}`,lang);

    let finalData = {}
    const promises1 = json.items.map(x => parseData(x, lang));
    // console.log(promises1)
    await Promise.all(promises1).then(results => {
      finalData = results;
    });

    return finalData;
}



async function parseData(response, lang) {
  let parsedData = {}

  parsedData['created'] = response.created
  parsedData['lastModified'] = response.lastModified
  parsedData['schemaName'] = response.schemaName
  parsedData['status'] = response.status
  parsedData['cmsid'] = response.id
  Object.keys(response.data).forEach(item => {
      if (item != "content") {
        // console.log(lang)
        parsedData[item] = response.data[item][lang] ? response.data[item][lang] : response.data[item]["iv"];
      }
  });

  // console.log(response.data.content.iv)
  if (response.data.content) {

    parsedData["content"] = null
    const content = await getContentId(response.data.content.iv, lang);

    // content.items.forEach(async function(g,i){
    //   parsedData["content"][i] = await parseContent(g, lang)
    // }

    let promises2 = content.items.map(g => parseContent(g, lang));

    await Promise.all(promises2).then(results => {
      parsedData["content"] = results;
      // console.log("RESULT2")
      // console.log(parsedData["content"])
    });

  }
  // console.log("parseData DONE")
  // console.log(parsedData)

return parsedData;

}



export async function parseContent(response, lang) {

  let parsedContent = {}

  parsedContent['created'] = response.created
  parsedContent['lastModified'] = response.lastModified
  parsedContent['schemaName'] = response.schemaName
  parsedContent['status'] = response.status
  parsedContent['cmsid'] = response.id

  if(response.data){
    // console.log("data exists!!!")

    Object.keys(response.data).forEach(async function (item) {

      if (!item || item != "content") {
        parsedContent[item] = response.data[item][lang] ? response.data[item][lang] : response.data[item]["iv"];
      }

  });


  if(response.data.content) {

    // let promisesx = Object.keys(response.data).map(async function (item) {
    // console.log("content exists!!!")
    // asdf['content'] = {}
    let vvv = {}
    // console.log("Subcontent -- recursive")
    // console.log(item)
    // console.log(response.data.content.iv)
    const abc = await getContentId(response.data.content.iv)
    // console.log("ABC")
    // console.log(abc)
    // let xyz
    // console.log("subcontent done")
    // console.log(abc)
    // await Promise.resolve(abc).then(results => {
    //   xyz = results;
    //   // console.log("RESULT1")
    //   // console.log(a)
    // });
    //
    //
    // let promises3 = xyz.items.map(g => parseContent(g, lang));
    // await Promise.all(promises3).then(results => {
    //   asdf["content"] = results;
    //   console.log("RESULT3")
    //   console.log(asdf["content"])
    // });


    abc.items.forEach(async function(g,i){
      // console.log("ITERACE")
      // console.log(g)
      // console.log(await parseContent(g, lang))
      vvv[i] = await parseContent(g, lang)
      // console.log(vvv)

      parsedContent["content"] = vvv;
    });


  }
// await Promise.all(promisesx).then(results => {
//   // let fff = results;
//   console.log("RESULTX")
//   console.log(results)
// });

// console.log("hotovo")
  // x["content"] = getContentId(response.data.content.iv);
}
// console.log(parsedContent)
return parsedContent;

}

export function parseTexts(content, lang){
  let output = {};

    // console.log(content)
  // content.map(item => {
  //
  // })
  for (var item in content) {
    if (item != "content") {
      if(content[item]){
          output[item] = content[item][lang] ? content[item][lang] : content[item]["iv"];
      }

    } else {
      // console.log("TBD")
    }
  }
  // console.log("OUTPUT")
  // console.log(output)
  return output;
}


// function parseData2(response, lang) {
//   console.log(lang)
//     return {
//         id: response.id,
//         data: response.data,
//         // title: response.data.title ? response.data.title[lang] : '',
//         // text: response.data.text ? response.data.text[lang]: '',
//         // img: response.data.image ? process.env.VUE_APP_API_ENDPOINT + '/api/assets/' + process.env.VUE_APP_NAME + '/' + response.data.image.iv : '' ,
//     };
// }
