import { createStore } from 'vuex';
import { getDataByID } from "../service";

export default createStore({
  state() {
    return {
      price: 0,
      gameData: {},
      lang: "",
      reservationModal: false,
      gameModal: false
    }
  },
  mutations:{
    updatePrice(state,data){
      state.price = data;
    },
    openReservations(state){
      state.reservationModal = true;
    },
    closeReservations(state){
      state.reservationModal = false;
    },
    openGameModal(state){
      state.gameModal = true;
    },
    closeGameModal(state){
      state.gameModal = false;
    },
    async loadGameData(state,data){
      state.gameData = await getDataByID(data, localStorage.getItem("lang"));
    }
  }
})
