<template>
  <div
    class="section section-medium"
    v-if="data"
    :id="data.id"
    :data-cd-section="data.title"
  >
    <div class="container">
      <form class="form-inline vouchers" action="/purchase/pay" method="post">
        <generalContent
          v-for="(item, i) in data.content"
          :data="item"
          v-bind:key="i"
        />

        <div class="row">
          <div class="offset-lg-8 offset-md-6 col text-end">
            <input
              type="email"
              class="form-control mt-1 md-1"
              id="email"
              name="email"
              placeholder="Zadejte váš e-mail"
              onclick="select()"
              required
            />
            <div class="btn btn-success btn-disabled active md-1 mt-1">
              <b
                >Cena: <span id="finalPrice" class="ml-2 mr-1">{{ price }}</span
                >Kč</b
              >.
            </div>
            &nbsp;&nbsp;
            <button type="submit" class="btn btn-small  btn-primary md-1 mt-1">
              Zakoupit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import generalContent from "./content/General.vue";

import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "Vouchers",
  components: {
    generalContent
  },
  props: {
    data: Object
  },
  data: () => ({}),
  setup() {
    const store = useStore();
    const price = computed(() => store.state.price);
    // this.price = price;
    return { price };
  }
};
</script>
