<template>
  <div
    class="modal fade"
    id="gameModal"
    tabindex="-1"
    aria-labelledby="gameModal"
    aria-hidden="true"
  >
    <div class="modal-dialog" v-if="gameData">
      <div class="modal-content">
        <div class="modal-header d-flex">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ gameData.Title }}
          </h5>
          <button
            type="button"
            class="btn-close pd-5 shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body p-4">
          <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-5 col-sm-6">
              <div
                class="iframe-box"
                style="width:100%;height: 0;padding-bottom: 56.25%;
  position: relative;"
              >
                <iframe
                  width="550"
                  height="275"
                  id="gameModalVideo"
                  style="width:100%; height: 100%;
                  position: absolute;
                 top:0; left:0;"
                  :src="gameData.ytvideo"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div class="col">
              <span
                v-for="(item, i) in gameData.tag"
                v-bind:key="i"
                class="m-1 badge rounded-pill bg-info"
                v-html="item"
              />
              <div v-html="gameData.Description"></div>
            </div>
          </div>
          <hr />
          <div class="gallery row" v-if="gameData.gallery">
            <div
              v-for="(item, i) in gameData.gallery"
              v-bind:key="i"
              class="p-1 col-lg-3 col-md-4 col-sm-6"
            >
              <img
                :src="$assetsUrl + item + '?width=420&height=240'"
                :alt="item.Title"
                @click="this.show(i)"
                style="width:100%"
              />
            </div>
          </div>
        </div>

        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
      </div>
    </div>
  </div>

  <vue-easy-lightbox
    moveDisabled
    :visible="lightboxVisible"
    :index="index"
    :imgs="imgs"
    @hide="hide"
  >
  </vue-easy-lightbox>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { Modal } from "bootstrap";

export default {
  name: "GameModal",
  components: {},
  data: () => ({
    lightboxVisible: false,
    index: 0,
    imgs: null
  }),
  setup() {
    const store = useStore();
    const gameData = computed(() => store.state.gameData);
    const isOpen = computed(() => store.state.gameModal);
    function close() {
      store.commit("closeGameModal");
    }
    return { gameData, isOpen, close };
  },
  watch: {
    isOpen: function(val) {
      if (val) {
        this.modal.show();
      } else {
        this.modal.hide();
      }
    }
  },
  methods: {
    show(i) {
      this.imgs = this.gameData.gallery.map(x => this.$assetsUrl + x);
      this.index = i;
      this.lightboxVisible = true;
    },
    hide() {
      this.lightboxVisible = false;
    }
  },
  mounted() {
    this.modal = new Modal(document.getElementById("gameModal"));

    document.getElementById("gameModal").addEventListener(
      "hidden.bs.modal",
      function() {
        this.close();
        document
          .getElementById("gameModalVideo")
          .contentWindow.postMessage(
            '{"event":"command","func":"stopVideo","args":""}',
            "*"
          );
      }.bind(this)
    );
  }
};
</script>
