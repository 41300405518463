<template>
  <div
    class="section contact"
    id="contact"
    data-cd-section="Kontakt"
    v-if="data"
  >
    <!-- v-if="data"
    :id="data.id"
    :data-cd-section="data.title" -->

    <div id="map" class="big-map"></div>
    <div class="container container-xl">
        <div class="transparent-white card card-contact card-raised">
          <div
            class="transparent card-header header-raised header-primary text-center"
          >
            <h4 class="card-title">{{ section.title }}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="smaller-padding info info-horizontal">
                  <i class="icon material-icons text-danger" id="loc">location_on</i>
                  <div
                    class="description"
                    itemprop="location"
                    itemscope
                    itemtype="http://schema.org/Place"
                  >
                    <h5 class="info-title">Adresa
                      </h5>
                    <p>
                      <b>{{ section.address.lineOne }}</b> <br />
                      {{ section.address.lineTwo }} <br />
                      {{ section.address.lineThree }} <br />
                      {{ section.address.openingHours }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="smaller-padding info info-horizontal">

                    <i class="icon material-icons text-info">perm_contact_calendar</i>

                  <div class="description">
                    <h5 class="info-title">Recepce</h5>
                    <p>
                      {{ section.reception.email }} <br />
                      {{ section.reception.phone }}
                    </p>
                    <br />
                    <h5 class="info-title">Vedení</h5>
                    <p>
                      {{ section.main.email }} <br />
                      {{ section.main.phone }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<!-- <script src="https://api.mapy.cz/loader.js"></script> -->
<script>
// Loader.load()

export default {
  name: "Contact",
  props: {
    data: Object
  },
  data: function() {
    return {
      section: {
        title: "Kontakty",
        reception: {
          phone: "720 414 309",
          email: "recepce@virty.cz"
        },
        main: {
          phone: "606 726 636",
          email: "info@virty.cz"
        },
        address: {
          lineOne: "Virty",
          lineTwo: "Nuselská 133/134",
          lineThree: "140 00 Praha 4",
          openingHours: "Po - Ne: 14:00 - 22:00"
        }
      }
    };
  },
  methods: {
    createMap() {
      // var center = this.SMap.Coords.fromWGS84(16.2565694, 49.2385542);
      // var place = this.SMap.Coords.fromWGS84(16.2565694, 49.2406542);
      const center = window.SMap.Coords.fromWGS84(14.4524, 50.0555);
      var place = window.SMap.Coords.fromWGS84(14.4524489, 50.0546836);
      var m = new window.SMap(window.JAK.gel("map"), center, 18);

      m.addDefaultLayer(window.SMap.DEF_BASE).enable();

      // m.addDefaultControls();
      // m.addDefaultContextMenu();
      const controlMouse = new window.SMap.Control.Mouse(window.SMap.MOUSE_PAN);
      const controlZoom = new window.SMap.Control.Zoom({a:10,b:20});
      m.addControl(controlMouse)
      m.addControl(controlZoom,{right:"10px", top:"80px"})

      const layer = new window.SMap.Layer.Marker();
      m.addLayer(layer);
      layer.enable();

      let location = document.createElement("i");
      location.setAttribute("class", "material-icons text-info");
      location.setAttribute("style", "font-size:45px;");
      location.innerHTML="location_on"
      var options = {
        title: 'Virty - Herna virtuální reality',
        url: location,
        anchor: {left:23,top:45}
      };
      var marker = new window.SMap.Marker(place, "Virty", options);
      layer.addMarker(marker);

      // var mouseControl = null;
      // var controls = m.getControls();
      //
      // for (var i = 0; i < controls.length; i++) {
      //   if (controls[i] instanceof window.SMap.Control.Mouse)
      //     mouseControl = controls[i];
      // }
      // mouseControl.configure(window.SMap.MOUSE_PAN | this.SMap.MOUSE_ZOOM);
    },
    reloadMap() {
      window.Loader.async = true;
      window.Loader.load(null, null, this.createMap);
    }
  },
  created() {
    let mapyjs = document.createElement("script");
    mapyjs.setAttribute("src", "https://api.mapy.cz/loader.js");
    mapyjs.id = "mapscript";
    mapyjs.async = true;
    document.head.appendChild(mapyjs);
  },
  unmounted() {
    window.removeEventListener("resize", this.reloadMap);
  },
  watch: {
    data() {
      this.$nextTick(function () {
        if (window.Loader) {
          this.reloadMap();
        } else {
          document.getElementById("mapscript").onload = this.reloadMap;
        }
      })
    }
  },
  mounted() {
    this.$nextTick(function() {
    if (window.Loader) {
      this.reloadMap();
    }
      window.addEventListener("resize", this.reloadMap);
    })
  }
};
</script>
